export const documentToAcceptPreOfferEvaluationRequestResolution = (document) => ({
  id: document.preoffer.preofferevaluationrequest.id,
  ratification: document.ratification,
  monthlyRate: document.monthlyRate,
  defaultRate: document.defaultRate,
  retentionRate: document.retentionRate,
  fundingDays: document.fundingDays,
  reviewOc: document.reviewOc,
  reviewHes: document.reviewHes,
  reviewPortalScreenshot: document.reviewPortalScreenshot,
  hasReceptionAcknowledgment: document.hasReceptionAcknowledgment,
  automaticDecision: true,
});

export const documentToAcceptOrderingSimulationEvaluationRequestResolution = (document) => ({
  requestId: document.orderingsimulation.id,
  factoringMonthlyRate: document.monthlyRate,
  factoringRetentionRate: document.retentionRate,
  factoringDefaultRate: document.defaultRate,
  orderingMonthlyRate: document.orderingMonthlyRate,
  orderingRetentionRate: document.orderingRetentionRate,
  orderingDefaultRate: document.orderingDefaultRate,
  fundingDays: document.fundingDays,
});
