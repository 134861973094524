import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { RISK_DEFAULT_RESULT } from '../graphql/default/RiskDefaultResultQuery';

const useRiskDefaultResults = (riskEvaluationId) => {
  const { loading, data } = useQuery(RISK_DEFAULT_RESULT, {
    variables: { riskEvaluationId },
  });

  const offeredResult = useMemo(() => data?.offeredRiskDefaultResult, [data]);
  const rejectedResult = useMemo(() => data?.rejectedRiskDefaultResult, [data]);

  const offeredResultAccepted = offeredResult?.isAccepted;
  const rejectedResultRejected = rejectedResult?.isRejected;

  const isResultUnknown = !offeredResultAccepted && !rejectedResultRejected;
  const isPredictionResultRejected = !offeredResultAccepted && rejectedResultRejected;

  return {
    loading,
    predictionResult: isPredictionResultRejected ? rejectedResult : offeredResult,
    isResultUnknown,
  };
};

export default useRiskDefaultResults;
