import { gql } from '@apollo/client';
import { MasterEntityBasicField } from '@fingo/lib/graphql/customers/fragment';
import { CreditLineFields } from './fragments';

export const CREATE_CREDIT_LINE = gql`
  mutation CreateCreditLine(
    $masterEntityId: String!
    $currency: String!
    $limit: Float!
  ) {
    createCreditLine(
      masterEntityId: $masterEntityId
      currency: $currency
      limit: $limit
    ) {
      masterEntity {
        ...MasterEntityBasicField
        creditlines {
          edges {
            node {
              ...CreditLineFields
            }
          }
        }
      }
    }
  }
  ${CreditLineFields}
  ${MasterEntityBasicField}
`;

export const EDIT_CREDIT_LINE = gql`
  mutation EditCreditLine($creditLineId: String!, $newLimit: Float!) {
    editCreditLine(creditLineId: $creditLineId, newLimit: $newLimit) {
      masterEntity {
        ...MasterEntityBasicField
        creditlines {
          edges {
            node {
              ...CreditLineFields
            }
          }
        }
      }
    }
  }
  ${CreditLineFields}
  ${MasterEntityBasicField}
`;
