import ContractTypeSelect from '../components/risk-evaluations/forms/SelectContractType';
import LegalRepresentativeAutocomplete from '../components/risk-evaluations/forms/LegalRepresentativeAutocomplete';
import SelectableInvoicesTable from '../components/risk-evaluations/forms/SelectableInvoicesTable';
import SelectableOrderingTable from '../components/risk-evaluations/forms/SelectableOrderingTable';

const dynamicRestrictionsField = {
  CONTRACT_SIGNING: [
    {
      fieldName: 'contractType',
      label: 'Seleccione tipo de contrato',
      component: ContractTypeSelect,
      options: [
        { value: 'NOTARY', label: 'Notarial' },
        { value: 'DIGITAL', label: 'Digital' },
      ],
    },
    {
      fieldName: 'endorser',
      label: 'Seleccione el aval',
      component: LegalRepresentativeAutocomplete,
    },
  ],
  COLLECTION_DELINQUENT_INVOICE: [
    {
      fieldName: 'triggeringDocumentIds',
      label: 'Selecione factura(s) morosa(s)',
      component: SelectableInvoicesTable,
      documentType: 'invoice',
    },
  ],
  COLLECTION_DELINQUENT_PURCHASEORDER: [
    {
      fieldName: 'triggeringDocumentIds',
      label: 'Seleccione OC morosa(s)',
      component: SelectableOrderingTable,
      documentType: 'purchase_order',
    },
  ],
  GET_PAYMENT_DATE_FOR_OVERDUE_INVOICES: [
    {
      fieldName: 'triggeringDocumentIds',
      label: 'Selecione factura(s) morosa(s)',
      component: SelectableInvoicesTable,
      documentType: 'invoice',
    },
  ],
  REGULARIZE_REFUND: [
    {
      fieldName: 'triggeringDocumentIds',
      label: 'Selecione factura(s) para reintegro',
      component: SelectableInvoicesTable,
      documentType: 'invoice',
    },
  ],
  REGULARIZE_REPLACEMENT: [
    {
      fieldName: 'triggeringDocumentIds',
      label: 'Selecione factura(s) para reemplazo',
      component: SelectableInvoicesTable,
      documentType: 'invoice',
    },
  ],
  PREPAY_OVERDUE_INVOICE: [
    {
      fieldName: 'triggeringDocumentIds',
      label: 'Selecione factura(s) morosa(s)',
      component: SelectableInvoicesTable,
      documentType: 'invoice',
    },
  ],
};

export default dynamicRestrictionsField;
