import React from 'react';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { formatMoney, toPercentage } from '@fingo/lib/helpers';

const valueHandler = (dataVariables, row) => {
  if (row.valueType === 'NoneType') {
    return 'n/a';
  }
  if (row.valueType === 'bool') {
    return row.value === 'true' ? 'Si' : 'No';
  }
  if (row.valueType === 'int' || row.valueType === 'float') {
    const floatValue = parseFloat(row.value);
    if (dataVariables[row.name] === undefined) {
      return floatValue;
    }
    if (dataVariables[row.name].variableType === '$') {
      return `$${formatMoney(floatValue)}`;
    }
    if (dataVariables[row.name].variableType === '%') {
      return `%${toPercentage(floatValue)}`;
    }
    return floatValue;
  }
  return row.value;
};

const riskEvaluationsVariablesColumns = (variablesDescription) => ([
  {
    field: 'name',
    type: 'string',
    headerName: 'Nombre',
    align: 'center',
    renderCell: ({ row }) => (
      <Tooltip title={variablesDescription[row.name]?.description || 'n/a'}>
        <Typography>
          {variablesDescription[row.name]?.labelName || 'n/a'}
        </Typography>
      </Tooltip>
    ),
    flex: 1,
  },
  {
    field: 'value',
    type: 'string',
    headerName: 'Valor',
    align: 'center',
    renderCell: ({ row }) => (
      <Typography>
        {valueHandler(variablesDescription, row)}
      </Typography>
    ),
    flex: 1,
  },
]);

export default riskEvaluationsVariablesColumns;
