import { gql } from '@apollo/client';

export const RiskDefaultResultTypeFragment = gql`
  fragment RiskDefaultResultTypeFragment on RiskDefaultResultType {
    id
    prediction
    modelProbability
    thresholdUsed
    isAccepted
    isRejected
  }
`;

export const RiskDefaultVariableContributionModelTypeFragment = gql`
  fragment RiskDefaultVariableContributionModelTypeFragment on RiskDefaultVariableContributionType {
    id
    variableName {
      id
      name
      labelName
    }
    variableValue
    contribution
  }
`;
