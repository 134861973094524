/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const GET_MASTER_ENTITY_METABASE_URL = gql`
  query masterEntity($masterEntityId: Int!) {
    getMasterEntity(masterEntityId: $masterEntityId) {
      id
      metabaseRiskUrl
    }
  }
`;
