/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

import { RiskDefaultVariableContributionModelTypeFragment } from './fragments';

export const RUN_RISK_DEFAULT_CONTRIBUTION = gql`
  mutation runRiskDefaultContribution(
    $riskDefaultResultId: Int!
  ) {
    runRiskDefaultContribution(
      riskDefaultResultId: $riskDefaultResultId
    ) {
      riskDefaultResult {
        id
        variablesContribution {
          ... RiskDefaultVariableContributionModelTypeFragment
        }
      }
    }
  }
  ${RiskDefaultVariableContributionModelTypeFragment}
`;
