export const RISK_FACTORING_COLUMNS = {
  Chile: [
    'folio-invoice-profile',
    'amountWithIva',
    'siiStatus',
    'receiver_sortable',
    'monthlyRate',
    'defaultRate',
    'retentionRate',
    'fundingDays',
    'ratification',
    'documentsReview',
  ],
  Mexico: [
    'folio',
    'receiver_sortable',
    'monthlyRate',
    'defaultRate',
    'retentionRate',
    'fundingDays',
  ],
};

export const DEFAULT_RATIFICATION = {
  Chile: '',
  Mexico: 'WITHOUT_RESTRICTION_CLIENT',
};

export const DEFAULT_FUNDING_DAYS = {
  Chile: 60,
  Mexico: 120,
};

export const WHITHOUT_RATIFICATION_STATUS = [
  'WHITHOUT_RATIFICATION_DEBTOR',
  'WHITHOUT_RATIFICATION_CLIENT',
];

export const ORDERING_COLUMNS = [
  'orderNumber',
  'purchaser_Name',
  'totalAmount',
  'orderingMonthlyRate',
  'orderingDefaultRate',
  'orderingRetentionRate',
  'monthlyRate',
  'defaultRate',
  'retentionRate',
  'fundingDays',
  'orderingsimulation_InvoiceIssuedDate',
  'orderingsimulation_InvoiceDateToPay',
];
