import React from 'react';
import moment from 'moment';
import { CompanyProfileCell } from '@fingo/lib/components/cells';
import { numberToMoney } from '@fingo/lib/helpers';
import CreditLineSuggestion from '../components/credit-line-evaluation-requests/CreditLineSuggestion';

export const HEADER_TITLE = 'Evaluaciones de líneas de crédito';

export const COLUMNS = [
  {
    field: 'masterEntity_Name',
    headerName: 'Empresa',
    renderCell: ({ row }) => <CompanyProfileCell masterEntity={row} />,
    sortable: true,
    flex: 2,
  },
  {
    field: 'requestDate',
    headerName: 'Fecha de solicitud',
    valueGetter: ({ row }) => row.creditLine?.creditLineEvaluationRequests?.requestDate,
    valueFormatter: ({ value }) => moment(value).format('DD/MM/YYYY hh:mm A'),
    width: 150,
  },
  {
    field: 'suggestion',
    headerName: 'Sugerencia',
    valueGetter: ({ row }) => row
      .creditLine?.creditLineEvaluationRequests?.creditlinesuggestionSet[0]?.amount,
    renderCell: CreditLineSuggestion,
    width: 150,
  },
  {
    field: 'limitAmount',
    type: 'number',
    headerName: 'Linea total',
    headerAlign: 'center',
    filterable: false,
    sortable: false,
    valueGetter: ({ row }) => {
      const currency = row.creditLine?.currency?.isoCode;
      const amount = row.creditLine?.limitAmount;
      return (amount ? numberToMoney(amount, currency) : '-');
    },
    valueFormatter: ({ value }) => (value || '-'),
    width: 140,
  },
  {
    field: 'creditUsed',
    type: 'number',
    headerName: 'Linea usada',
    headerAlign: 'center',
    filterable: false,
    sortable: false,
    valueGetter: ({ row }) => {
      const currency = row.creditLine?.currency?.isoCode;
      const amount = row.creditLine?.creditUsed;
      return (amount ? numberToMoney(amount, currency) : '-');
    },
    valueFormatter: ({ value }) => (value || '-'),
    width: 120,
  },
  {
    field: 'approvedAmount',
    type: 'number',
    headerName: 'Monto',
    headerAlign: 'center',
    filterable: false,
    sortable: false,
    valueGetter: ({ row }) => {
      const currency = row.creditLine?.currency?.isoCode;
      const amount = row.approvedAmount;
      return (amount ? numberToMoney(amount, currency) : '-');
    },
    valueFormatter: ({ value }) => (value || '-'),
    width: 120,
  },
  {
    field: 'comment',
    type: 'string',
    headerName: 'Comentario',
    headerAlign: 'center',
    filterable: false,
    sortable: false,
    valueGetter: ({ row }) => row.comment,
    valueFormatter: ({ value }) => value,
    width: 160,
  },
  {
    field: 'status',
    type: 'string',
    headerName: 'Solución',
    headerAlign: 'center',
    filterable: false,
    sortable: false,
    valueGetter: ({ row }) => row.status,
    valueFormatter: ({ value }) => value,
    width: 160,
  },
  {
    field: 'currency',
    type: 'string',
    headerName: 'Divisa',
    headerAlign: 'left',
    filterable: false,
    sortable: false,
    valueGetter: ({ row }) => row.creditLine?.currency?.isoCode,
    valueFormatter: ({ value }) => value,
    width: 80,
  },
];
