/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';

export const RESOLVE_RATIFICATION = gql`
  mutation resolveRatification(
    $ratificationManagerIds: [Int]!
    $accepted: Boolean!
    $evidenceFile: Upload
    $evidenceContactId: Int
    $resolutionType: String!
    $observation: String
  ) {
    resolveRatification(
      ratificationManagerIds: $ratificationManagerIds
      accepted: $accepted
      evidenceFile: $evidenceFile
      evidenceContactId: $evidenceContactId
      resolutionType: $resolutionType
      observation: $observation
    ) {
      success
    }
  }
`;

export const UPDATE_REVIEW_DOCUMENTS_MANAGER = gql`
  mutation UpdateReviewDocumentsManager($newStates: [UpdateReviewDocumentsManagerInputType]!) {
    updateReviewDocumentsManager(newStates: $newStates) {
      invoices {
        id
        offer {
          id
          reviewDocumentsManager {
            id
            ocReviewState
            hesReviewState
            portalScreenshotReviewState
          }
        }
      }
    }
  }
`;
