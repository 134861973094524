/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import {
  TypeConnectionFields,
  sharedInvoiceInput,
  sharedInvoiceInputFields,
} from '@fingo/lib/graphql';
import { InvoiceDocumentsFields } from '@fingo/lib/graphql/invoices/fragments';
import { CompanyDebtsSummary } from '@fingo/lib/graphql/customers/fragment';
import { UserIdentification } from '@fingo/lib/graphql/user/fragments';
import {
  CompanyPendingDocumentsReviewFields,
  InvoicePendingDocumentsReviewFields,
  RecieverPendingDocumentsReviewFields,
  ReviewDocumentsManagerFields,
  UniqueDocumentFinanceStatePendingDocumentsReviewFields,
} from './fragments';

export const GET_PENDING_DOCUMENTS_REVIEW_INVOICES = gql`
  query getPendingDocumentsReviewInvoices(${sharedInvoiceInputFields}) {
    invoices(${sharedInvoiceInput}) {
      ... TypeConnectionFields
      edges {
        cursor
        node {
          ...InvoicePendingDocumentsReviewFields
          uniqueDocumentFinanceState {
            ...UniqueDocumentFinanceStatePendingDocumentsReviewFields
          }
          company {
            ...CompanyPendingDocumentsReviewFields
            masterEntity {
              ...CompanyDebtsSummary
            }
            executiveAssigned {
              ... UserIdentification
            }
          }
          receiver {
            ...RecieverPendingDocumentsReviewFields
          }
          documents {
            ...InvoiceDocumentsFields
          }
          offer {
            id
            reviewDocumentsManager {
              ... ReviewDocumentsManagerFields
            }
          }
        }
      }
    }
  }
  ${CompanyDebtsSummary}
  ${TypeConnectionFields}
  ${InvoicePendingDocumentsReviewFields}
  ${UniqueDocumentFinanceStatePendingDocumentsReviewFields}
  ${CompanyPendingDocumentsReviewFields}
  ${RecieverPendingDocumentsReviewFields}
  ${InvoiceDocumentsFields}
  ${ReviewDocumentsManagerFields}
  ${UserIdentification}
`;
