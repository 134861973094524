// eslint-disable-next-line import/prefer-default-export
export const slaResolutionHelper = (row, documentType) => {
  if (documentType === 'invoice') {
    return row?.preoffer?.preofferevaluationrequest?.slaResolution;
  }
  if (documentType === 'preoffer') {
    return row?.preofferevaluationrequest?.slaResolution;
  }
  if (documentType === 'preofferEvaluationRequest') {
    return row?.slaResolution;
  }
  if (documentType === 'purchaseorder') {
    return row?.orderingsimulation?.slaResolution;
  }
  return row;
};
