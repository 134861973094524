import React from 'react';
import CheckCircle from '@mui/icons-material/CheckCircle';
import Cancel from '@mui/icons-material/Cancel';

const RiskRatificationColumns = [
  {
    field: 'variableDescription__labelName',
    headerName: 'Variable',
    flex: 0.3,
    valueGetter: ({ row }) => row.variableDescription.labelName,
  },
  {
    field: 'variableValue',
    headerName: 'Valor',
    align: 'center',
    valueGetter: ({ row }) => row.variableValue,
  },
  {
    field: 'contribution',
    headerName: 'Contribución',
    align: 'center',
    valueGetter: ({ row }) => row.contribution.toFixed(3),
  },
  {
    field: 'contributionMatch',
    headerName: '',
    align: 'center',
    valueGetter: ({ row }) => row.contribution,
    renderCell: ({ row }) => (
      row.contribution > 0 ? <CheckCircle sx={{ color: 'green' }} /> : <Cancel sx={{ color: 'red' }} />
    ),
  },
];

export default RiskRatificationColumns;
