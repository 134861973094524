import React from 'react';
import Typography from '@mui/material/Typography';
import { green, red } from '@mui/material/colors';
import { moneyFormat } from '@fingo/lib/helpers';

const RiskPredictionsColumns = [
  {
    field: 'variable',
    headerName: 'Variable',
    flex: 1,
    valueGetter: ({ row }) => row.variableName.labelName || row.variableName.name,
  },
  {
    field: 'value',
    headerName: 'Valor',
    align: 'center',
    flex: 0.4,
    valueGetter: ({ row }) => moneyFormat(row.variableValue),
  },
  {
    field: 'contribution',
    headerName: 'Contribución',
    align: 'center',
    flex: 0.4,
    valueGetter: ({ row }) => row.contribution,
    renderCell: ({ row }) => (
      <Typography color={(row.contribution > 0 ? green[500] : red[500])}>
        <b>{row.contribution.toFixed(4)}</b>
      </Typography>
    ),
  },
];

export default RiskPredictionsColumns;
