/* eslint-disable import/prefer-default-export */
import { gql } from '@apollo/client';
import {
  RiskDefaultResultTypeFragment,
  RiskDefaultVariableContributionModelTypeFragment,
} from './fragments';

export const RISK_DEFAULT_RESULT = gql`
  query RiskDefaultResult($riskEvaluationId: Int!) {
    offeredRiskDefaultResult(riskEvaluationId: $riskEvaluationId) {
      ...RiskDefaultResultTypeFragment
      predictionModel {
        id
        predictionType {
          id
          resultEnum
        }
      }
      variablesContribution {
        ...RiskDefaultVariableContributionModelTypeFragment
      }
    }
    rejectedRiskDefaultResult(riskEvaluationId: $riskEvaluationId) {
      ...RiskDefaultResultTypeFragment
      predictionModel {
        id
        predictionType {
          id
          resultEnum
        }
      }
      variablesContribution {
        ...RiskDefaultVariableContributionModelTypeFragment
      }
    }
  }
  ${RiskDefaultResultTypeFragment}
  ${RiskDefaultVariableContributionModelTypeFragment}
`;
